import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";

function MobileMenu() {
    useEffect(() => {
        // Mobile Menu
        const hmBtn = document.getElementById("mb-sidebar-btn");

        if (hmBtn) {
            const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
            const mdSidebarBody = document.getElementById("mb-sidebar-body");
            const mdSidebarExit = document.getElementById("close-mb-sidebar");

            hmBtn.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.toggle("visible");
                mdSidebarBody.classList.toggle("opened");
            });

            mdSidebarOverlay.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });

            mdSidebarExit.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });
        }

        // Menu Accordion -----------------
        const menuButton = document.querySelectorAll(".mb-menu-button");
        menuButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "mb-menu-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "mb-menu-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

    return (
        <Styles>
            {/* Mobile Menu */}
            <section className="mobile-menu-area">

                <Container>
                    <Row>
                        <Col md="0" sm="12">         
                            <div className="mb-logo-area d-flex justify-content-between">
                                <div className="mb-logo-box d-flex">
                                    <div className="hm-button">
                                        <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                                            <i className="las la-bars"></i>
                                        </a>
                                    </div>
                                    <div className="mb-logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/logo/logo.png"}/></Link>
                                    </div>
                                </div>                         
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mobile Menu Sidebar */}

            <section className="mb-sidebar" id="mb-sidebar-body">
                <div className="mb-sidebar-heading d-flex justify-content-between">
                    <div><h5>Menu</h5></div>
                    <div><a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar"><i className="las la-times"></i></a></div>
                </div>
                <div className="mb-sidebar-menu">
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p><Link to={process.env.PUBLIC_URL + "/"}>Home</Link> <i className="fa fa-home"></i></p>
                        </button>                       
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Portals <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                            <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://adoptaschool.bodoland.gov.in/" }} target="_blank">BTR School Adoption </Link></li>                                              
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://kharithi.bodoland.gov.in/" }} target="_blank">kharithi</Link></li>                                              
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://super50.bodoland.gov.in/" }} target="_blank"  >Super-50 for Engineering </Link></li>                                              
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://super50upsc.bodoland.gov.in/" }} target="_blank"  >CEM's Super-50 for UPSC</Link></li>                                             
                                                                                        
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://scholarships.gov.in/" }} target="_blank"  >Scholarships</Link></li>       
                              
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Notifications <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/notice"}>Notice</Link></li>
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/tenders"}>Tenders</Link></li>                                               
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>   
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Recruitment <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                            <li className="nav-item"><Link className="nav-link" to={{ pathname : "https://recruitment.bodoland.gov.in/TET/"  }} target="_blank" >LP&UP TET</Link></li>
                             <li className="nav-item"><Link className="nav-link" to={{ pathname : "https://recruitment.bodoland.gov.in/"  }} target="_blank" >PG&UG Teacher</Link></li>
                              
                            </ul>
                        </div>
                    </div>





                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Who's Who <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                              <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/cem"}>Executive Member</Link></li>
                              <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/secretary"}>Secretary</Link></li>
                              <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/director"}>Director</Link></li>
                              
                            </ul>
                        </div>
                    </div> */}
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Information Services <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://kharithi.bodoland.gov.in/"}} target="_blank">School Databank</Link></li>
                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://src.udiseplus.gov.in/" }} target="_blank"   >UDISE</Link></li>    
                                                                                     
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/#"}>Education Act & Rules</Link></li>
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/#"}>Download</Link></li>
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/#"}>RTI Contacts</Link></li>                                           
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li> 
                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "#"}>Educational Callendar</Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Events <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                              <li className="nav-item"> <Link className="nav-link" to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>                              
                            </ul>
                        </div>
                    </div> */}
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>About Us <i className="las la-plus"></i></p>
                        </button>  
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                             <li className="nav-item"> <Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>  
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Gallary <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                              <li className="nav-item"> <Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery"}>Gallary</Link></li>  
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Login <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                            <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/adminLogin' target="_blank">Admin Login</a> </li>
                              <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/chd/login' target="_blank">CHD Login</a> </li>
                              <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/officer/login' target="_blank">Officers Login</a> </li>
                              <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/teacherLogin' target="_blank">Teacher Login</a> </li>
                              <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/headTeacherLogin' target="_blank">School Login</a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
        </Styles>
    )
}

export default MobileMenu