import React, { useEffect } from 'react';
import Datas from '../data/footer/footer2.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerTwo.js";

function FooterTwo() {
    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2">           
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="footer-logo-info mt-5">
                                <img src={process.env.PUBLIC_URL + "/assets/logo/logo-white.png"} width="130%" alt="" className="img-fluid" />                               
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="f-links">
                                <h5>Useful Links</h5>
                                <ul className="list-unstyled">
                                    <li><a href='https://bodoland.gov.in/'><i className="las la-angle-right"></i>Govt. of Bodoland</a></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Grievance Redressal Portal </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>General Information</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="3">
                        <div className="f-links">
                                <h5>Policies</h5>
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Copyright Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Hyperlinking Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Privacy Policy </Link></li>                                   
                                </ul>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="f-newsletter list-unstyled">
                                <h5>Contact Us</h5>                      
                                <p>Office of the Director of Education BTC, Kokrajhar Station Road <br/>                           
                              
                              
                                  <i className="las la-map-marker"></i> Kokrajhar, BTR, Assam - 783370 <br/> 
                                  <i className="las la-envelope"></i> education.btr@gmail.com  
                               
                               


                             </p>
                              
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="copytext-area text-center">
                                <p>Copyright &copy; 2022 | Designed & Developed With <i className="las la-heart"></i> by <a href={process.env.PUBLIC_URL + "/"} target="_blank" rel="noopener noreferrer">Department of Education, BTR</a></p>
                             </div>
                        </Col>
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />
            </footer>
        </Styles>
    );
}

export default FooterTwo
