import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {

    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Mandate</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Organisational Structure</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i> Director of Education, BTC</Nav.Link>
                                        </Nav.Item>                                
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Mandate of The Department</h4>
                                            <p className="tab-desc text-justify">The Education Department is the transferred subject to the BTC and is the Nodal Department for proper Administration and maintenance of Educational Sector under the BTR Administrative area. The office of the Director Education, BTC being the Council Head of Department is the overall authority for academic and management of the entire Education department of BTR. All the Sectors of the Education department under BTR are looked after by the Director of Education, BTC from Elementary to Higher (College) level of Education including Sanskrit Tolls andMadrasas, except the Technical Education and University.

                                                The BTC Secretariat along with Executive Member, Education Department,BTR is the Administrative Head of the Departmentwho take policy directions for the entire department of Education for implementation.</p>
                                       
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Organisational Structure</h4>
                                            <p className="tab-desc text-justify">
                                            There are four tier Managerial and Administrative set up in the Education Department under the BTR such as the CHD i.e. the Director of Education, BTC for the entire BTR.

                                            The office of the Director of Education BTC is the top of the Managerial Structure of Educational Administration under BTR for proper Administration and maintenance of the Department. He is the overall authority for academic and management of the entire Education Department of BTR. The Director of Education BTC looks after all the Sectors of the Education Department under the BTC administrative jurisdiction from Elementary to Higher (College) level including Sanskrit Tolls and Madrassas, except the Technical Education and University level.

                                            There are office of the Inspector of Schools in the district level for proper maintenance and management of the High, Higher Secondary and Senior Secondary level of Education and the office of the District Elementary Education Officers for Elementary stage of Education in the district under BTR.

                                            In the Sub-Division level for the management and administration of Elementary Education there are the office of the Deputy Inspector of Schools who monitor directly the schools of the elementary level (LP& UP Schools) for the 10 Sub-Divisions under BTR. While the office of the Block Elementary Education Officers (also works as Block Mission Coordinator for SSA) looks after the Elementary level of Education in the Block level under the BTR.
                                            </p>
                                      
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Director of Education (DE), BTC</h4>
                                            <p className="tab-desc text-justify">Director of Education, BTC is the overall authority for proper management and supervision of the Educational Administration under BTR in every aspect. All the Elementary Secondary and Higher level of Education is under the supervision of Director Education, BTC specially the Higher Education sector in the BTR is controlled by him. He controls the office of the DE, BTC and other subordinate offices of different levels of education department. He looks after the matter of infrastructure development, skill development, various deficiencies, various grievances of the teachers, students or community regarding educational matters under BTR and take necessary remedial measures for the same. Educationaltraining for teaching staff, recruitment process, transfer of teaching and non teaching staff and office personals as per requirement are made by the Director of Education, BTC for proper maintenance and Administration of the department as per Govt. norms and according prior approval of the BTC authority. He also participate in various meetings with secretary level in BTC / State Govt.,SSA, RMSA and other subordinate officers to elevate the Department activities.
                                            </p>                                       
                                        </Tab.Pane>
                                   
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TabBox
