import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/homeBlog.js";
import axios from 'axios';

class HomeBlog extends Component {

    constructor(props){
        super(props)
        this.state = {
            Datas:[]
        }
    }

    componentDidMount(){

       
        axios.get('https://edu.bodoland.gov.in/api/v1/latestUpdate')
       
        .then(respose => {         
           this.setState({Datas:respose.data.data.latestUpdate});                
        })

        .catch(error =>{
            console.log(error);
        })

    }  

    render() {

        const {Datas} = this.state; 
        
        const url =  'https://edu.bodoland.gov.in/api/v1/latestUpdate';  
        return (
            <Styles>
                {/* Blog Area */}
                <section className="home-blog-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>Latest Events</h4>
                                </div>
                            </Col>
                            {
                                Datas.slice(0, 4).map((data, i) => (
                                    <Col md="6" key={i}>
                                        <div className="blog-post">
                                            <Row>
                                                <Col lg="6" md="12">
                                                    <div className="blog-img">
                                                        <Link to={process.env.PUBLIC_URL + `#`}><img src={data.doc_profile_image} width='200%' className="img-fluid" /></Link>
                                                    </div>
                                                </Col>
                                                <Col lg="6" md="12">
                                                    <div className="blog-content">                                                     
                                                       <div className="content-box">
                                                            <div className="top-content d-flex">
                                                            
                                                                <div className="blog-title">
                                                                  
                                                                    <h6><Link to={process.env.PUBLIC_URL + "/event-details/" + data.id}>{data.update_title}</Link></h6>
                                                                    <strong className='text-success'><i class="fas fa-calendar-alt"></i> {data.date}</strong>
                                                                </div>
                                                            </div>
                                                            <div className="blog-desk text-justify">
                                                                <p>{data.update_desc.substring(0, 150)}</p>                                                             
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ))
                            }

                           <Col md="12">
                                <div className="sec-title text-center">                      
                                <Link to={process.env.PUBLIC_URL + "/events"} class="btn btn-light btn-lg btn-block">Read More</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default HomeBlog
