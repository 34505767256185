import React, { Component } from 'react';
import Datas from '../data/about-us/about-us.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import { Styles } from "./styles/aboutUs.js";

class AboutUs extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <Styles>
                {/* About Us */}
                <section className="about-us">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="about-image">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/oci.jpg`} className="main-img" alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/pattern.png"} className="pattern-img" alt="" />
                                    <div className="video-player" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/oci.jpg)`}}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OYnpkQ24rhU' onClose={() => this.setState({ isOpen: false })} />
                                        <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="about-content">
                                    <h4 className="about-title"> <span className='text-success'>खारिथि</span> One Click Information for Teachers & Schools (OCI)</h4>
                                    <p className="about-para text-justify">Khariti is an initiative from Director of Education, BTC to share the authentic data of academic and infrastructural details of Govt, Aided and Recognized schools in BTR. Khariti opens the doors to view the basic details of schools including its physical infrastructure, governing mechanisms etc.<span>
                                    Details which are available in Khariti include the approved classes in each school, details of students in each class, teachers and non-teaching staff (without personal details) etc which can be used for various report generations at school, educational sub-district, educational district, revenue district and Council level. </span></p>
                                    <Row>
                                        <Col sm="4">
                                            <div className="counter-box box1 text-center">
                                                <h3><CountUp end={5574} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p> Schools</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box2 text-center">
                                                <h3><CountUp end={23137} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Teachers</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box3 text-center">
                                                <h3><CountUp end={742301} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Students</p>
                                            </div>
                                        </Col>
                                    </Row>                                   
                                    <a href='https://kharithi.bodoland.gov.in/' target="_blank" className='readmore-btn'>Click Here</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default AboutUs
