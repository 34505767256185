import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav, Table } from 'react-bootstrap';
import HeaderTwo from '../../components/Header';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/productDetails.js';

class ProductDetails extends Component {
    render() {
        const settings = {
            showArrows: false,
            showStatus: false,
            showIndicators: false,
        };

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper product-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Who's Who" />

                    {/* Product Details */}
                    <section className="product-details-area">
                        <Container>
                            <Row>
                                <Col md="4">
                                    <div className="product-slider">                                      
                                    
                                     <div className="slider-item">
                                         <img src={process.env.PUBLIC_URL + `/assets/images/photos/de.jpg`}  className="img-fluid" />
                                     </div>                                        
                                             
                                    </div>
                                </Col>

                                <Col md="8">
                                    <div className="product-information">
                                        <div className="product-title">
                                            <h4>Sri J. P. Brahma AES</h4>
                                        </div>                                
                                     
                                        <div className="product-desc">
                                            <p>Director of Education <br/> Bodoland Territorial Council</p>
                                        </div>
                                        <div className="product-stock">                                         

                                            <p>Email   : director@education.bodoland.gov.in</p>                                                                   
                                                                                   
                                        </div>                        
                                  

                                     

                                        <div className="product-cart-wh-com-btn">
                                           
                                            <Link to={process.env.PUBLIC_URL + "#"} className="wishlist-btn"><i class="fab fa-facebook-f"></i></Link>
                                            <Link to={process.env.PUBLIC_URL + "#"} className="compare-btn"><i class="far fa-envelope-open"></i></Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col md="12">
                                    <div className="product-tab">
                                        <Tab.Container defaultActiveKey="description">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="description">MESSAGE FROM THE DIRECTOR'S TABLE</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="additional">Additional Information</Nav.Link>
                                                </Nav.Item>                                             
                                            </Nav>

                                            <Tab.Content>
                                                <Tab.Pane eventKey="description" className="description-tab text-justify">
                                                  <p><strong>Education department of Bodoland Territorial Council, </strong> has played a vital role in moulding the student community as well as the teaching and non-teaching community within the BTC from its inception. Infrastructure development of the Schools & Colleges was the prime moto of the Department along with improvement in teaching & learning activities.

                                                    The Education Department, BTC directly manages schools, teachers,non-teaching staff of the department under BTR. The Department plays an eminent role in the physical and mental development of all pupils studying in schools, Colleges & Universities. There has been tremendous growth in educational facilities at all levels of education in the council during the last 18 years. The private and public sectors together played significant role in attaining the present level and their contribution to the field are remarkable.The department administers school education from pre-primary level to the degree level and teacher training.

                                                    BTC's achievements in social development and quality of life are, no doubt, inspiring and encouraging. BTC has achieved a human development index comparable to the developed States in India. Better education kindles the aspirations of the people and the main concern is on how to improve the quality of education.

                                                   <br/> <strong > "By education I mean an all-round drawing out of the best in child and man-body, mind and spirit. Literacy is not the end of education or even the beginning." </strong> M.K Gandhi.

                                                    This website is an attempt to place the department transparent and ease of work to the Student, Teaching & Non-Teaching staff of the department. Your valuable suggestions shall add more value to this effort.</p>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="additional" className="additional-tab">
                                                    <Table className="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td>Email</td>
                                                                <td>director@education.bodoland.gov.in</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Phone</td>
                                                                <td>+91 123456789</td>
                                                            </tr>                                                                                                         
                                                        </tbody>
                                                    </Table>                                                
                                                </Tab.Pane>
                                            </Tab.Content>

                                           

                                        </Tab.Container>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default ProductDetails