import React, { Component } from 'react';
import Datas from '../data/faq-event/faq-event.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/faqEvent";
import axios from 'axios';


class FaqEvent extends Component {  

    constructor(props){
        super(props)
        this.state = {
            Datas:[]
        }
    }

    componentDidMount(){

       
        axios.get('https://edu.bodoland.gov.in/api/v1/notice')
        .then(respose => {         
           this.setState({Datas:respose.data.data.notice});                
        })

        .catch(error =>{
            console.log(error);
        })

    }  
    
    render() {

        const {Datas} = this.state;  
        
        const url =  'https://edu.bodoland.gov.in/api/v1/notice';
        console.log(url);

        const marqueeStyle = {
            display: "inline-block", 
            paddingRight: "50px",                              
        };

        return (
            <Styles>
               
               <section className="event-faq-area">
                <hr></hr>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="event-area">
                                <Row>
                                    <Col md="3">
                                        <div className="sec-title">
                                          <h4>Notices <span> <img src={process.env.PUBLIC_URL + "assets/images/arrow.png"} width="15%"/></span></h4>
                                           
                                         </div> 
                                                                            
                                    </Col>
                                    <Col md="9">                                    
                                        <marquee className="mt-2">
                                            {
                                                Datas.map((data, i) => (
                                                    <ul style={marqueeStyle}>
                                                         <li  className='marqueeLine' key={i}> <h6><Link style={{ color: '#808080' }} to={process.env.PUBLIC_URL + "/notice-details/" + data.id}> {data.notice_title} </Link></h6> </li>

                                                    </ul>
                                                    
                                                                                                        
                                                    ))  


                                            }
                                        
                                         
                                        </marquee>    
                                        <a className='btn btn-success btn-sm float-right' href={process.env.PUBLIC_URL + "/notice"}>Read All</a>                                 
                                    </Col>                          
                                </Row>
                            </div>
                        </Col>   
                    </Row>
                </Container>
          
            </section>

                
            </Styles>
        )
    }
}

export default FaqEvent
