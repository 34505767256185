import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/Header';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/eventDetails.js';
import axios from 'axios';

class EventDetails extends Component {  

  

    constructor(props){
        super(props)      
     
        this.state = {
            Datas:[]
        }
    }
    componentDidMount(){
          
        const id = window.location.pathname;
        const lastItem = id.substring(id.lastIndexOf('/') + 1)
              
        const url = `https://edu.bodoland.gov.in/api/v1/latestUpdate/${lastItem}` ;
        axios.get(url)
        .then(respose => {       
           this.setState({Datas:respose.data.data.latestUpdate});   
             
        })

        .catch(error =>{
            console.log(error);
        })

    }


    render() {
        
        const {Datas} = this.state;
        console.log(Datas);

        return (               

            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Event Details" />

                    {/* Event Details Area */}
                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    {
                                        
                                    Datas.map((data, i)=>(

                                        <div className="event-details-content" key={i}>
                                        <div className="heading">
                                            <h4>{data.update_title}</h4>
                                        </div>
                                        <div className="event-icon">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><i className="las la-calendar"></i>{data.date}</li>                                             
                                            </ul>
                                        </div>
                                        <div className="event-details-banner">
                                            <img src={data.doc_cover_image} alt="" className="img-fluid" />
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>Event Overview</h5>
                                            <p className='text-justify'>{data.update_desc}</p>                             
                                        </div>   
                                        {/* <button type="button" class="btn btn-outline-warning btn-lg"> <i class="fas fa-download"></i> Download</button>                        */}
                                    </div>


                                    ))

                                    }                      

                               </Col>                    
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default EventDetails