import React, { Component } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { Styles } from "./styles/heroSlider.js";


class HeroSlider extends Component {
    render() {  

        
        return (
            <Styles>          

            <Carousel>
            <Carousel.Item interval={3000}>
                     <img className="d-block w-100" src={process.env.PUBLIC_URL + `/assets/images/hero/hero.jpg`} alt="Image Three" />            
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                    <img className="d-block w-100" src={process.env.PUBLIC_URL + `/assets/images/hero/H1.jpg`} alt="Image One" />                   
                    </Carousel.Item>
                    {/* <Carousel.Item interval={3000}>
                     <img className="d-block w-100" src={process.env.PUBLIC_URL + `/assets/images/hero/H2.jpg`} alt="Image Two" />            
                    </Carousel.Item>
                 
                    <Carousel.Item interval={3000}>
                     <img className="d-block w-100" src={process.env.PUBLIC_URL + `/assets/images/hero/H4.jpg`} alt="Image Four" />            
                    </Carousel.Item> */}
                </Carousel>
            </Styles>
        )
    }
}

export default HeroSlider
