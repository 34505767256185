import React, { Component } from 'react';
import Header from './components/Header';
import HeroSlider from './components/HeroSlider';
import IconBox from './components/IconBox';
import OneClickInfo from './components/OneClickinfo';
import MarqueeEvents from './components/MarqueEvents';
import AboutDept from './components/AboutDept';
import MainNotice from './components/MainNotice';
import Footer from './components/FooterTwo';


export default class HomeOne extends Component {
    render() {
        return (
            <div className="main-wrapper" >

                {/* Header */}
                < Header />

                {/* Hero Slider */}
                < HeroSlider />

                {/* Icon Box */}
                < IconBox />              

                {/* MarqueeEvents */}
                < MarqueeEvents />

                {/* Help Area */}
                < AboutDept />            
                           
                {/* About Area */}
                < OneClickInfo />           
        
                {/* Blog Area */}
                < MainNotice />           


                {/* Footer */}
                < Footer />

            </div >
        )
    }
}
