import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";

class HeaderTwo extends Component {
    render() {
        return (
            <Styles>
                {/* Topbar 2 */}
                {/* <section className="top-bar2">
                    <Container>
                        <Row>
                            <Col lg="7" md="9">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-phone"></i>+91- 7896747971</li>
                                        <li className="list-inline-item"><i className="las la-envelope"></i> contact@education.bodoland.gov.in</li>                                    
                                   </ul>
                                </div>
                            </Col>
                            <Col lg="5" md="3">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline bar-lang">
                                        <li className="list-inline-item">
                                            <Dropdown>
                                                <Dropdown.Toggle><img src="https://cdn0.iconfinder.com/data/icons/tuts/256/google_translate.png" alt="" />English<i className="las la-angle-down"></i></Dropdown.Toggle>
                                                <Dropdown.Menu as="ul">
                                                    <Dropdown.Item as="li">English</Dropdown.Item>                                                                                   
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled list-inline bar-social">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>                                    
                                      
                                    </ul>                          
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}

                {/* Logo Area 2 */}
                <section className="logo-area2">
                    
                    <Container>
                        <Row>
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/logo/logo.png"} width="115%" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="menu-box d-flex justify-content-end">
                                    <ul className="nav menu-nav">
                                        <li className="nav-item dropdown active">
                                            <Link className="nav-link" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Home </Link>                                        
                                        </li>
                                       
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Portals <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://adoptaschool.bodoland.gov.in/" }} target="_blank">BTR School Adoption </Link></li>                                              
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://kharithi.bodoland.gov.in/" }} target="_blank">kharithi</Link></li>                                              
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://super50.bodoland.gov.in/" }} target="_blank"  >Super-50 for Engineering </Link></li>                                              
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://super50upsc.bodoland.gov.in/" }} target="_blank"  >CEM's Super-50 for UPSC</Link></li>                                             
                                                                                        
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://scholarships.gov.in/" }} target="_blank"  >Scholarships</Link></li>                                              
                                                {/* <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "#"}>Educational Callendar</Link></li> */}
                                                {/* <li className="nav-item dropdown active">
                                                    <Link className="nav-link" to={process.env.PUBLIC_URL + "/about"} data-toggle="dropdown">About Us </Link>                                        
                                                </li>   */}
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Notifications <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/notice"}>Notice</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/tenders"}>Tenders</Link></li>                                               
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>                                               
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Recruitment<i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname : "https://recruitment.bodoland.gov.in/TET/"  }} target="_blank" >LP&UP TET</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={{ pathname : "https://recruitment.bodoland.gov.in/"  }} target="_blank" >PG&UG Teacher</Link></li>
                                    
                                            </ul>
                                        </li>
                                        {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Who's Who <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/cem"}>Executive Member</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/secretary"}>Secretary</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/director"}>Director</Link></li>
                                            
                                            </ul>
                                        </li> */}
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Information Services <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">
                                               {/* <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://kharithi.bodoland.gov.in/"}} target="_blank">School Databank</Link></li> */}
                                               <li className="nav-item"><Link className="nav-link" to={{ pathname: "https://src.udiseplus.gov.in/" }} target="_blank"   >UDISE</Link></li>    
                                                                                     
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/#"}>Education Act & Rules</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/#"}>Download</Link></li>
                                                 <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "#"}>Educational Callendar</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/#"}>RTI Contacts</Link></li>                                           
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>                                           
                                            </ul>
                                        </li>                            
                                                          
                                        {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/events"} data-toggle="dropdown">Events</Link>                                        
                                        </li>                                                                                 */}

                                         <li className="nav-item dropdown">
                                         <Link className="nav-link" to={process.env.PUBLIC_URL + "/about"} data-toggle="dropdown">About Us </Link>                                         
                                        </li>                                                                                
                                        
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/gallery"} data-toggle="dropdown">Gallary</Link>                                        
                                        </li>    
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Login <i className="las la-angle-down"></i></Link>
                                            <ul className="dropdown list-unstyled">                                      
                                                   
                                                <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/adminLogin' target="_blank">Admin Login</a> </li>
                                                <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/chd/login' target="_blank">CHD Login</a> </li>
                                                <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/officer/login' target="_blank">Officers Login</a> </li>
                                                <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/teacherLogin' target="_blank">Teacher Login</a> </li>
                                                <li className="nav-item"> <a className="nav-link" href='https://education.bodoland.gov.in/Dashboard/public/headTeacherLogin' target="_blank">School Login</a> </li>
                                                                                      
                                               
                                            </ul>
                                        </li>   
                                                             
                                    </ul>                           
                                
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenu />

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default HeaderTwo
