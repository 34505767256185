import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Tab, Nav, Table } from 'react-bootstrap';
import HeaderTwo from '../../components/Header';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/productDetails.js';

class ProductDetails extends Component {
    render() {
        const settings = {
            showArrows: false,
            showStatus: false,
            showIndicators: false,
        };

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper product-details-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Who's Who" />

                    {/* Product Details */}
                    <section className="product-details-area">
                        <Container>
                            <Row>
                                <Col md="4">
                                    <div className="product-slider">                                      
                                    
                                     <div className="slider-item">
                                         <img src={process.env.PUBLIC_URL + `/assets/images/photos/PB.jpg`}  className="img-fluid" />
                                     </div>                                        
                                             
                                    </div>
                                </Col>

                                <Col md="8">
                                    <div className="product-information">
                                        <div className="product-title">
                                            <h4>Sri Pramod Boro (Hon'ble CEM of BTR)</h4>
                                        </div>                                
                                     
                                        <div className="product-desc">
                                            <p>Hon'ble CEM of BTR <br/> Bodoland Territorial Council</p>
                                        </div>
                                        <div className="product-stock">                                         

                                            <p>Email   : cem@education.bodoland.gov.in</p>                                                                   
                                                                                   
                                        </div>                        
                                  

                                     

                                        <div className="product-cart-wh-com-btn">
                                           
                                            <Link to={process.env.PUBLIC_URL + "#"} className="wishlist-btn"><i class="fab fa-facebook-f"></i></Link>
                                            <Link to={process.env.PUBLIC_URL + "#"} className="compare-btn"><i class="far fa-envelope-open"></i></Link>
                                        </div>
                                    </div>
                                </Col>

                                {/* <Col md="12">
                                    <div className="product-tab">
                                        <Tab.Container defaultActiveKey="description">
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="description">MESSAGE FROM THE CEM'S TABLE</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="additional">Additional Information</Nav.Link>
                                                </Nav.Item>                                             
                                            </Nav>

                                            <Tab.Content>
                                                <Tab.Pane eventKey="description" className="description-tab text-justify">
                                                  <p><strong>Education department of Bodoland Territorial Council, </strong> Pramod Boro (Born 1 March 1975) is an Indian politician serving as the president of The United People's Party Liberal (UPPL) since 25 February 2020 and Chief Executive Member of Bodoland Territorial Region, an autonomous region in Assam since 15 December 2020. He was the former president of All Bodo Students Union. Pramod Boro was born to Late Ghanashyam Boro. He is a resident of Tamulpur, Baksa district.</p>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="additional" className="additional-tab">
                                                    <Table className="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td>Email</td>
                                                                <td>cem@education.bodoland.gov.in</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Phone</td>
                                                                <td>+91 123456789</td>
                                                            </tr>                                                                                                         
                                                        </tbody>
                                                    </Table>                                                
                                                </Tab.Pane>
                                            </Tab.Content>

                                           

                                        </Tab.Container>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default ProductDetails