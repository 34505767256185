import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/Header';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/event.js';
import axios from 'axios';


class Events extends Component {  

    constructor(props){
        super(props)
        this.state = {
            Datas:[]
        }
    }

    componentDidMount(){

       
        axios.get('https://edu.bodoland.gov.in/api/v1/tender')
        .then(respose => {         
           this.setState({Datas:respose.data.data.tender});                
        })

        .catch(error =>{
            console.log(error);
        })

    }  
    
    render() {

        const {Datas} = this.state;  
        
        const url =  'https://edu.bodoland.gov.in/api/v1/tender';
        console.log(url);

     
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Tenders" />                

                    {/* Events Area */}
                    <section className="event-page-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12">
                                 {    
                                        Datas.map((data, i) => (
                                            <div className="event-box" key={i}>
                                                <Row>
                                                    <Col xl="3" lg="4" md="0">
                                                        <div className="event-img">
                                                        <Link to={process.env.PUBLIC_URL + data.id}><img src={process.env.PUBLIC_URL + `/assets/images/event-03.png`} alt="" className="img-fluid" /></Link>
                                                        </div>
                                                    </Col>
                                                    <Col xl="9" lg="8" md="12">
                                                        <div className="event-content">
                                                            <div className="content-box">
                                                                <Row>
                                                                    <Col md="9">
                                                                        <div className="event-title">
                                                                            <h6><Link to={process.env.PUBLIC_URL + "/tenders-details/" + data.id}>{data.tender_name}</Link></h6>
                                                                        </div>
                                                                        <div className="event-time-location">
                                                                            <ul className="list-unstyled list-inline">
                                                                                <li className="list-inline-item"><i className="las la-clock"></i> {data.published_date}</li>                                                                                
                                                                            </ul>
                                                                        </div>
                                                                        <div className="event-desc text-justify">
                                                                            <p>{data.tender_description}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="3" className="text-center">
                                                                        <div className="event-date">
                                                                         
                                                                        </div>
                                                                        <div className="join-btn mt-3">                                                                          
                                                                            <Link to={process.env.PUBLIC_URL + "/tenders-details/" + data.id}>View Tender</Link>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))                                     
                           
                                                                        

                                    }

                                    {/* <Col md="12" className="text-center">
                                        <Pagination />
                                    </Col> */}
                                </Col>

                                {/* <Col lg="3" md="0">
                                    <CourseSidebar />
                                </Col> */}

                            </Row>
                        </Container>
                    </section>


                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default Events